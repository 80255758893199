import { LoanInformationType } from '../offer.types';
import { en } from './en';

export const sv: typeof en = {
  actions: {
    select: 'Välj',
    detail: 'Detaljer',
    gotoBank: 'Gå till banken',
  },
  status: {
    recommended: 'Populär',
    canceled: 'Avbruten',
    withdrawn: 'Tillbakadragen',
    expired: 'Utgången',
    selected: 'Vald',
    paid: 'Utbetald',
  },
  labels: {
    day_one: '{{count}} dag',
    day_other: '{{count}} dagar',
    month_one: '{{count}} månad',
    month_other: '{{count}} månader',
    year_one: '{{count}} år',
    year_other: '{{count}} år',
    yes: 'ja',
    no: 'nej',
    required: 'krävs',
  },
  loan: {
    amount: 'Lånebelopp',
    duration: 'Löptid',
    effectiveRate: 'Effektiv ränta',
    monthlyPayment: 'Månadskostnad',
    nominalRate: 'Nominell ränta',
    startupFee: 'Uppläggningsavgift',
    administrationFee: 'Aviavgift',
    loanType: 'Lån',
    documentation: {
      required: 'Kräver dokumentation',
      notRequired: 'Ingen dokumentation behövs',
    },
    types: {
      'Consumer Loan': 'Konsumentlån',
      'Refinance Loan': 'Refinansieringslån',
      'Combo Loan': 'Kombinationslån',
      'Topup Loan': 'Tilläggslån',
    },
    information: {
      'Consumer Loan':
        'Låneerbjudandet är ett konsumtionslån där du kan använda pengarna till vad du vill.',
      'Refinance Loan':
        'Låneerbjudandet är ett refinansieringslån där gammal och dyr skuld ersätts med ett billigare lån.',
      'Combo Loan':
        'Låneerbjudandet är en kombination av refinansieringslån och konsumtionslån, där en del går till att ersätta gammal och dyr skuld, och resten betalas ut till ditt konto.',
      'Topup Loan':
        'Låneerbjudandet ovan inkluderar ditt befintliga lån hos denna bank.',
      'Refinance Loan Dual Offer':
        'Detta låneerbjudande är ett alternativt refinansieringslån baserat på uppgifter från skuldregistret, som betalar av mer av din skuld till ett billigare lån.',
      'Combo Loan Detailed':
        'Låneerbjudandet är en kombination av refinansieringslån och konsumtionslån, där {{refinanceAmount}} går till att ersätta gammal och dyr skuld, och {{cashAmount}} betalas ut till ditt konto.',
    } satisfies Record<LoanInformationType, string>,
  },
  creditCard: {
    effectiveRate: 'Effektiv ränta',
    creditLimit: 'Kreditgräns',
    interestFreePeriod: 'Räntfri period',
  },
  note: {
    pending: 'Väntar på svar från banken... vänligen vänta',
    information: 'Nyttig information',
    highCostWarning: {
      title: 'Högkostnadsvarning',
      description:
        'Om du inte kan betala tillbaka hela skulden riskerar du en betalningsanmärkning. För stöd, vänd dig till budget- och skuldrådgivningen i din kommun. Kontaktuppgifter finns på <a href="https://www.hallakonsument.se">hallakonsument.se</a>.',
    },
    statusDescription: {
      canceled: {
        title: 'Erbjudande avbrutet / avslaget',
        description: '',
        action: true,
      },
      withdrawn: {
        title: 'Erbjudande tillbakadraget',
        description:
          'Vi arbetar på att förbättra ditt erbjudande. Ett nytt och bättre erbjudande kommer att vara tillgängligt snart.',
      },
      expired: {
        title: 'Erbjudande utgånget',
        description:
          'Tyvärr är detta erbjudande inte längre giltigt. Du kan kontakta oss för att försöka få erbjudandet förnyat.',
        action: true,
      },
    },
  },
};
