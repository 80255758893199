import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { clsx } from 'clsx';
import { FC } from 'react';

import styles from './HighCostInformationBanner.module.scss';
import { Card } from '@axo/ui-core/components/Card';

export const variants = ['card', 'default'] as const;
export type HighCostInformationProps = {
  variant?: (typeof variants)[number];
  className?: string;
};

const labels = {
  title: 'Att låna kostar pengar!',
  description:
    'Om du inte kan betala tillbaka skulden i tid riskerar du en betalningsanmärkning. Det kan leda till svårigheter att få hyra bostad, teckna abonnemang och få nya lån. För stöd, vänd dig till budget- och skuldrådgivningen i din kommun. Kontaktuppgifter finns på <a href="https://www.hallakonsument.se">hallakonsument.se</a>.',
};

const InformationBannerContent = ({ className }: { className?: string }) => (
  <Stack
    className={clsx(styles.highCostInformation, className)}
    gap={'3xs'}
    direction={'h'}
  >
    <div className={styles.icon}>
      <Icon name={'high-cost-warning-solid'} />
    </div>
    <div>
      <Text className={styles.title} size={'xs'} as={'p'}>
        {labels.title}
      </Text>
      {labels.description && (
        <Text
          className={styles.description}
          size={'xs'}
          dangerouslySetInnerHTML={{ __html: labels.description }}
          as={'p'}
        />
      )}
    </div>
  </Stack>
);

export const HighCostInformationBanner: FC<HighCostInformationProps> = ({
  variant = 'default',
  className,
}) => {
  return variant === 'default' ? (
    <InformationBannerContent className={className} />
  ) : (
    <Card>
      <InformationBannerContent className={className} />
    </Card>
  );
};
