import { EnrichedLoanQuote } from '../loan-quote.api.schema';

export const isHighCostLoan = (data: EnrichedLoanQuote): boolean => {
  let isHighCost = false;

  // only required in SE atm.
  if (data.MarketCountry === 'SE' && data.EffectiveRate > 32.75)
    isHighCost = true;

  return isHighCost;
};
