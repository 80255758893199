import { LoanInformationType } from '../offer.types';
import { en } from './en';

export const nb: typeof en = {
  actions: {
    select: 'Velg',
    detail: 'Detaljer',
    gotoBank: 'Gå til banken',
  },
  status: {
    recommended: 'Anbefalt',
    canceled: 'Avsluttet',
    withdrawn: 'Trukket tilbake',
    expired: 'Utløpt',
    selected: 'Valgt',
    paid: 'Utbetalt',
  },
  labels: {
    day_one: '{{count}} dag',
    day_other: '{{count}} dager',
    month_one: '{{count}} måned',
    month_other: '{{count}} måneder',
    year_one: '{{count}} år',
    year_other: '{{count}} år',
    yes: 'ja',
    no: 'nei',
    required: 'krever',
  },
  loan: {
    amount: 'Lånebeløp',
    duration: 'Nedbetalingstid',
    effectiveRate: 'Effektiv rente',
    monthlyPayment: 'Månedskostnad',
    nominalRate: 'Nominell rente',
    startupFee: 'Etableringskostnad',
    administrationFee: 'Termingebyr',
    loanType: 'Lånetyp',
    documentation: {
      required: 'Krever dokumentasjon',
      notRequired: 'Ingen dokumentasjon nødvendig',
    },
    types: {
      'Consumer Loan': 'Forbrukslån',
      'Refinance Loan': 'Refinansieringslån',
      'Combo Loan': 'Kombinasjonslån',
      'Topup Loan': 'Påfyllingslån',
    },
    information: {
      'Consumer Loan':
        'Lånetilbudet er et forbrukslån hvor du kan bruke pengene til hva du vil.',
      'Refinance Loan':
        'Lånetilbudet er et refinansieringslån hvor gammel og dyr gjeld blir erstattet med et billigere lån.',
      'Combo Loan':
        'Lånetilbudet er en kombinasjon av refinansieringslån og forbrukslån, hvor noe av lånet går til å erstatte gammel og dyr gjeld, og resten blir utbetalt til din konto.',
      'Topup Loan':
        'Lånetilbudet over inkluderer ditt eksisterende lån hos denne banken.',
      'Refinance Loan Dual Offer':
        'Dette lånetilbudet er et alternativt refinansieringslån basert på opplysninger fra gjeldsregister, som innfrir mer av gjelden din til et billigere lån.',
      'Combo Loan Detailed':
        'Lånetilbudet er en kombinasjon av refinansieringslån og forbrukslån, hvor {{refinanceAmount}} går til å erstatte gammel og dyr gjeld, og {{cashAmount}} blir utbetalt til din konto.',
    } satisfies Record<LoanInformationType, string>,
  },
  creditCard: {
    effectiveRate: 'Effektiv rente',
    creditLimit: 'Kredittgrense',
    interestFreePeriod: 'Rentefri periode',
  },
  note: {
    pending: 'Venter på banksvar... vennligst vent',
    information: 'Nyttig informasjon',
    highCostWarning: {
      title: 'Advarsel om høy kostnad',
      description:
        'Hvis du ikke kan tilbakebetale hele gjelden, risikerer du en betalingsanmerkning. For støtte, kontakt budsjett- og gjeldsrådgivningen i din kommune.',
    },
    statusDescription: {
      canceled: {
        title: 'Tilbud avslått / avvist',
        description: '',
        action: true,
      },
      withdrawn: {
        title: 'Tilbud trukket tilbake',
        description:
          'Vi jobber med å forbedre tilbudet ditt. Et nytt og bedre tilbud vil snart være tilgjengelig.',
      },
      expired: {
        title: 'Tilbud utløpt',
        description:
          'Dessverre er dette tilbudet ikke lenger gyldig. Du kan kontakte oss for å prøve å få tilbudet fornyet.',
        action: true,
      },
    },
  },
};
