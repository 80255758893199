import type { UnsecuredLoanCustomFields } from '../../@types/context/product-unsecured-loan-config.types';

export const unsecuredLoansBrokerCustomFields: UnsecuredLoanCustomFields[] = [
  // axo
  {
    hasTotalCostMonthly: false,
  },
  // zmarta
  {
    hasTotalCostMonthly: true,
    hasExtendedEmploymentStatus: true,
    alwaysAskPurpose: true,
    hasEmployerIndustry: true,
    hasJobPosition: true,
    hasApartmentType: true,
    hasLivedAtAddressSince: true,
    hasHousingDebtCreditor: true,
    hasPoliticallyInfluential: true,
    hasCoApplicantMonthlyExpenses: true,
  },
];
