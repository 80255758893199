import type { EnvConfig, EnvFile } from '../@types/context/env-config.types';
import { envSchema } from '../@types/context/env-config.types';

export function parseEnv(env: EnvFile = getEnv()): EnvConfig {
  const rawEnv = { ...(env || {}) }; // Capture all environment variables as raw input
  const filteredEnv = filterEnvBySchema(rawEnv, envSchema as EnvFile);

  const parsedConfig: EnvConfig = {
    __vars__: filteredEnv, // Store all raw variables under `__vars__`

    /**
     * currently based on VITE mode
     * TODO next.js env
     * @note don't base any code on this, aim to have the value in/from this config service
     * (exceptions: initialising 3rd party services)
     */
    env: (filteredEnv.MODE ??
      filteredEnv.NEXT_PUBLIC_ENV ??
      envSchema.NEXT_PUBLIC_ENV) as EnvConfig['env'],
    services: {
      apiUrl:
        filteredEnv.VITE_APP_API_URL ??
        filteredEnv.NEXT_PUBLIC_API_URL ??
        envSchema.NEXT_PUBLIC_API_URL,
      wlUrl: filteredEnv.NEXT_PUBLIC_WL_URL ?? envSchema.NEXT_PUBLIC_WL_URL,
    },
  };

  return parsedConfig;
}

// Utility function to detect and retrieve env variables
function getEnv(): EnvFile {
  if (
    typeof process !== 'undefined' &&
    process &&
    typeof process.env === 'object'
  ) {
    // For Next.js or Node.js
    return process.env as EnvFile;
  } else if (
    typeof import.meta !== 'undefined' &&
    import.meta &&
    typeof (import.meta as any).env === 'object'
  ) {
    // For Vite (check if `import.meta.env` is supported)
    return (import.meta as any).env as EnvFile;
  } else {
    // Explicitly throw your custom error
    throw new Error('No environment variables found. Unsupported environment!');
  }
}

function filterEnvBySchema<T extends Record<string, string>>(
  rawEnv: Record<string, any>, // Input raw environment variables
  schema: T // The schema against which keys are filtered and defaults are applied
): Record<keyof T, string> {
  return Object.keys(schema).reduce((acc, key) => {
    const value = rawEnv[key]; // Get raw env value
    if (value) acc[key as keyof T] = value;
    return acc;
  }, {} as Record<keyof T, string>);
}
