import * as Sentry from '@sentry/core';
import { useEffect } from 'react';
import { ZodError } from 'zod';
import { OfferData } from './offer.types';
import {
  LoanQuotePresentation,
  LoanQuotePresentationSchema,
} from './schema/loan-quote-presentation.api.schema';
import { OfferSchema } from './schema/offer.schema';
import { useOfferStore } from './store/useOfferStore';

type UseOffersProps = {
  onSelect?: (offer: OfferData | null) => void;
};

export const useOffers = ({ onSelect }: UseOffersProps = {}) => {
  const setOffers = useOfferStore((state) => state.setOffers);
  const isSelectEnabled = useOfferStore((state) => state.isSelectEnabled);
  const setIsSelectEnabled = useOfferStore((state) => state.setIsSelectEnabled);
  const deSelect = useOfferStore((state) => state.deSelect);

  const validateAndSetOffers = (data: LoanQuotePresentation) => {
    try {
      const { quotes } = LoanQuotePresentationSchema.parse(data);

      const offers = quotes.map((quote) => {
        try {
          return OfferSchema.parse(quote);
        } catch (quoteError) {
          console.error(`Error parsing quote:`, quoteError);

          const fields = (quoteError as ZodError).issues
            ?.map((issue) => issue.path.join('.'))
            .join(', ');
          Sentry.captureException(
            new Error(`Offers: Failed to parse quote, fields: ${fields}`),
            {
              extra: {
                error: quoteError,
                quote,
              },
            }
          );
          return null;
        }
      });

      setOffers(offers.filter((offer) => offer !== null));
    } catch (error) {
      console.error('Error parsing offers:', error);
      Sentry.captureException(new Error(`Offers: Failed to parse offers`), {
        extra: {
          error: error,
        },
      });
    }
  };

  useEffect(() => {
    const unsubscribe = useOfferStore.subscribe(
      (state) => state.selectedOffer,
      (offer: OfferData | null) => onSelect?.(offer)
    );

    return () => unsubscribe();
  }, [onSelect]);

  return {
    setQuotes: validateAndSetOffers,
    isSelectEnabled,
    setIsSelectEnabled,
    deSelect,
  };
};
