import { ConfigService } from '@axo/config';
import type { MyPageConfig } from '@axo/config/@types/context/my-page-config.types';

let config: MyPageConfig['options']['offers'];

try {
  config = ConfigService.get('my-page').options.offers;
} catch (error) {
  // default config if no context is available
  config = {
    isExpandedVariant: true,
    hasLoanType: false,
    hasDocumentationInfo: false,
  };
}

export { config };
