import { LoanInformationType } from '../offer.types';
import { en } from './en';

export const fi: typeof en = {
  actions: {
    select: 'Valitse',
    detail: 'Lue lisää',
    gotoBank: 'Jatka',
  },
  status: {
    recommended: 'Suositeltu',
    canceled: 'Suljettu tarjous',
    withdrawn: 'Peruutettu',
    expired: 'Vanhentunut',
    selected: 'Valittu',
    paid: 'Maksussa',
  },
  labels: {
    day_one: '{{count}} päivä',
    day_other: '{{count}} päivää',
    month_one: '{{count}} kuukausi',
    month_other: '{{count}} kuukautta',
    year_one: '{{count}} vuosi',
    year_other: '{{count}} vuotta',
    yes: 'kyllä',
    no: 'ei',
    required: 'pakollinen',
  },
  loan: {
    amount: 'Lainasumma',
    duration: 'Takaisinmaksuaika',
    effectiveRate: 'Tod. vuosikorko',
    monthlyPayment: 'Kuukausierä',
    nominalRate: 'Nimelliskorko',
    startupFee: 'Aloitusmaksu',
    administrationFee: 'Hallintomaksu',
    loanType: 'Laina',
    documentation: {
      required: 'Vaatii dokumentaation',
      notRequired: 'Ei vaadi dokumentaatiota',
    },
    types: {
      'Consumer Loan': 'Kulutusluotto',
      'Refinance Loan': 'Järjestelylaina',
      'Combo Loan': 'Yhdistelylaina',
      'Topup Loan': 'Täydennyslaina',
    },
    information: {
      'Consumer Loan':
        'Lainatarjous on kulutusluotto, jonka voit käyttää haluamaasi tarkoitukseen.',
      'Refinance Loan':
        'Lainatarjous on lainasi uudelleenrahoitusta varten, jolla vanha ja kallis velka korvataan yhdellä edullisella lainalla.',
      'Combo Loan':
        'Lainatarjous on sekä uudelleenrahoitusta että kulutusluottoa, jossa osa käytetään vanhan ja kalliin velan korvaamiseen, ja loput maksetaan tilillesi.',
      'Topup Loan':
        'Lainatarjous yllä sisältää nykyisen lainasi tässä pankissa.',
      'Refinance Loan Dual Offer':
        'Tämä lainatarjous on vaihtoehtoinen uudelleenrahoitus, joka perustuu velkarekisterin tietoihin ja jolla velkaasi voi maksaa edullisemmalla lainalla.',
      'Combo Loan Detailed':
        'Lainatarjous on sekä uudelleenrahoitusta että kulutusluottoa, jossa {{refinanceAmount}} käytetään vanhan ja kalliin velan korvaamiseen, ja {{cashAmount}} maksetaan tilillesi.',
    } satisfies Record<LoanInformationType, string>,
  },
  creditCard: {
    effectiveRate: 'Tod. vuosikorko',
    creditLimit: 'Luottoraja',
    interestFreePeriod: 'Korkovapaa aika',
  },
  note: {
    pending: 'Odotetaan pankin vastausta... ole hyvä ja odota',
    information: 'Lisätietoja',
    highCostWarning: {
      title: 'Huomio suuri korko',
      description:
        'Jos et voi maksaa koko velkaa takaisin, sinua uhkaa maksuhäiriömerkintä. Tukipalvelua saat ottamalla yhteyttä kuntasi talous- ja velkaneuvontaan.',
    },
    statusDescription: {
      canceled: {
        title: 'Tarjous peruutettu / hylätty',
        description: '',
        action: true,
      },
      withdrawn: {
        title: 'Tarjous vedetty takaisin',
        description:
          'Työskentelemme parantaaksemme tarjoustasi. Uusi parempi tarjous on pian saatavilla.',
      },
      expired: {
        title: 'Tarjous vanhentunut',
        description:
          'Valitettavasti tämä tarjous ei ole enää voimassa. Voit ottaa meihin yhteyttä yrittääksesi saada tarjouksen uusittua.',
        action: true,
      },
    },
  },
};
