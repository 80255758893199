import { ConfigService } from '@axo/config';
import { Separator } from '@axo/ui-core/components/Separator';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Bank } from '../../../../components/Partners';
import { namespace } from '../../locales/i18n.config';
import { formatDuration } from '../../locales/utils/format-duration';
import { LoanOfferData } from '../../offer.types';
import {
  OfferAnnotation,
  OfferAnnotationProps,
} from '../_elements/OfferAnnotation';
import { OfferHighCostInformation } from '../_elements/OfferHighCostInformation';
import {
  OfferInformation,
  OfferInformationProps,
} from '../_elements/OfferInformation';
import {
  OfferStateInformation,
  OfferStateInformationProps,
} from '../_elements/OfferStateInformation';
import { IOfferView } from '../offer-card.types';
import styles from './loanOffer.module.scss';
import { config } from '../../config/offers.config';

type DetailsViewData = { label: string; value: string };

export const LoanOffer: IOfferView<LoanOfferData> = ({
  data,
  layout = 'horizontal',
  showDetails = false,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(namespace);
  const { currency } = ConfigService.get('global');

  const Currency = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 0,
  });

  const Percentage = new Intl.NumberFormat(language, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const loanAmountData = useMemo(
    () => ({
      label: t('loan.amount'),
      value: Currency.format(data.Amount),
    }),
    [data]
  );

  const primaryData = useMemo(
    (): DetailsViewData[] => [
      {
        label: t('loan.duration'),
        value: formatDuration(data.durationTotalMonths, t, {
          style: 'compact',
          // threshold: 4,
        }),
      },
      {
        label: t('loan.nominalRate'),
        value: Percentage.format(data.NominalRate / 100),
      },
      {
        label: t('loan.monthlyPayment'),
        value: Currency.format(data.MonthlyPayment),
      },
    ],
    [data]
  );

  const secondaryData = useMemo(
    (): DetailsViewData[] => [
      ...(data.StartupFee !== undefined
        ? [
            {
              label: t('loan.startupFee'),
              value: Currency.format(data.StartupFee),
            },
          ]
        : []),
      ...(data.AdministrationFee !== undefined
        ? [
            {
              label: t('loan.administrationFee'),
              value: Currency.format(data.AdministrationFee),
            },
          ]
        : []),
      {
        label: t('loan.effectiveRate'),
        value: Percentage.format(data.EffectiveRate / 100),
      },
    ],
    [data]
  );

  const annotationLabels: OfferAnnotationProps['labels'] | null =
    config.hasLoanType || config.hasDocumentationInfo
      ? {
          ...(config.hasLoanType && data.LoanType
            ? { loanType: t(`loan.types.${data.LoanType}`) }
            : undefined),
          ...(config.hasDocumentationInfo
            ? {
                documentation: data.RequiresDocumentation
                  ? t('loan.documentation.required')
                  : t('loan.documentation.notRequired'),
              }
            : undefined),
        }
      : null;

  const loanInfoLabels: OfferInformationProps['labels'] | null =
    config.hasLoanType && data.informationType
      ? {
          description: t(`loan.information.${data.informationType}`, {
            refinanceAmount: data.RefinanceAmount
              ? Currency.format(data.RefinanceAmount)
              : undefined,
            cashAmount: data.CashAmount
              ? Currency.format(data.CashAmount)
              : undefined,
          }),
        }
      : null;

  const bankInfoLabels: OfferInformationProps['labels'] | null = data.bankInfo
    ?.length
    ? {
        list: [...data.bankInfo],
      }
    : null;

  const hasOfferDataVisible = !['withdrawn', 'expired'].includes(data.state);

  const hasOfferStateInfo = ['canceled', 'withdrawn', 'expired'].includes(
    data.state
  );

  const isActiveOffer = ['neutral', 'recommended', 'selected'].includes(
    data.state
  );
  const hasBankInfo = isActiveOffer && bankInfoLabels;
  const hasHighCostWarning = isActiveOffer && data.isHighCost;

  return (
    <div
      className={styles.loanOffer}
      data-layout={layout}
      data-show-details={
        showDetails || !!config.isExpandedVariant ? '' : undefined
      }
      data-offer-state={data.state}
    >
      <div className={styles.bank}>
        <Bank code={data.bank} country={data.country} />
      </div>
      <Separator
        className={styles.separator}
        orientation={'vertical'}
        color={'light'}
      />
      <div className={styles.data}>
        {hasOfferDataVisible && (
          <div className={styles.main}>
            <div className={styles.amount}>
              <span className={styles.value}>{loanAmountData.value}</span>
              <span className={styles.label}>{loanAmountData.label}</span>
            </div>
            <div className={styles.technical}>
              <div className={styles.primary}>
                {primaryData.map((d) => (
                  <div key={d.label}>
                    <span className={styles.value}>{d.value}</span>
                    <span className={styles.label}>{d.label}</span>
                  </div>
                ))}
              </div>
              <div className={styles.details}>
                <div className={styles.secondary}>
                  {secondaryData.map((d) => (
                    <div key={d.label}>
                      <span className={styles.value}>{d.value}</span>
                      <span className={styles.label}>{d.label}</span>
                    </div>
                  ))}
                </div>
                <div className={styles.info}>
                  {annotationLabels && (
                    <OfferAnnotation
                      className={styles.infoItem}
                      labels={annotationLabels}
                    />
                  )}
                  {loanInfoLabels && (
                    <OfferInformation
                      className={styles.infoItem}
                      labels={loanInfoLabels}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {hasOfferStateInfo && (
          <OfferStateInformation
            className={styles.state}
            state={data.state as OfferStateInformationProps['state']}
          />
        )}
        {hasBankInfo && hasOfferDataVisible && (
          <OfferInformation
            className={styles.bankInfo}
            labels={bankInfoLabels}
          />
        )}
        {hasHighCostWarning && hasOfferDataVisible && (
          <OfferHighCostInformation className={styles.warning} />
        )}
      </div>
    </div>
  );
};
