import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { Offer, OfferData } from '../offer.types';
import { OfferStore, OfferStoreState, SortBy } from './offer-store.types';
import { sortOffers } from './sort-offers.helper';

const initialState: OfferStoreState = {
  offers: [],
  selectedOffer: null,
  isSelectEnabled: true,
  sortBy: 'state',
};

const name = 'offer/offer-store';

const useOfferStore = create<OfferStore>()(
  subscribeWithSelector(
    devtools(
      (set, get) => ({
        ...initialState,

        setOffers: (offers: Offer[]) =>
          set(
            { offers: sortOffers(offers, get().sortBy) },
            undefined,
            'setOffers'
          ),
        select: (offer: OfferData) =>
          set({ selectedOffer: offer }, undefined, 'select'),

        deSelect: () => set({ selectedOffer: null }, undefined, 'deSelect'),

        setSortBy: (sortBy: SortBy) =>
          set(
            (state) => ({
              sortBy,
              offers: sortOffers(state.offers, sortBy),
            }),
            undefined,
            'setSortBy'
          ),

        setIsSelectEnabled: (isSelectEnabled: boolean) =>
          set({ isSelectEnabled }, undefined, 'setIsSelectEnabled'),
      }),
      {
        name,
      }
    )
  )
);

export { useOfferStore };
