import { Icon } from '@axo/ui-core/components/Icon';
import { Stack } from '@axo/ui-core/components/layout/item';
import { Text } from '@axo/ui-core/components/typography';
import { clsx } from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { namespace } from '../../locales/i18n.config';
import { OfferInformationProps } from './OfferInformation';

import styles from './offerHighCostInformation.module.scss';

export type OfferHighCostInformationProps = {
  className?: string;
};

export const OfferHighCostInformation: FC<OfferHighCostInformationProps> = ({
  className,
}) => {
  const { t } = useTranslation(namespace);

  const labels = t('note.highCostWarning', {
    returnObjects: true,
  }) as OfferInformationProps['labels'];

  return (
    <Stack
      className={clsx(styles.offerHighCostInformation, className)}
      gap={'3xs'}
      direction={'h'}
    >
      <div className={styles.icon}>
        <Icon name={'high-cost-warning-solid'} />
      </div>
      <div>
        <Text className={styles.title} size={'xs'} as={'p'}>
          {labels.title}
        </Text>
        {labels.description && (
          <Text
            className={styles.description}
            size={'xs'}
            dangerouslySetInnerHTML={{ __html: labels.description }}
            as={'p'}
          />
        )}
      </div>
    </Stack>
  );
};
