import { Country } from '@axo/config/@types/Country.types';
import { LoanType } from '@axo/shared/data-access/types/loan_quote/LoanType';
import { BankCode } from '../../components/Partners/Bank';
import { CreditCardCode } from '../../components/Partners/CreditCard';

export type OfferType = 'loan' | 'credit-card';
export type Offer =
  | { type: Extract<'loan', OfferType>; data: LoanOfferData }
  | { type: Extract<'credit-card', OfferType>; data: CreditCardOfferData };

export type OfferData = {
  id: string;
  state: OfferState;
};

export type LoanOfferData = OfferData & {
  bank: BankCode;
  country: Country;

  // direct
  Amount: number;
  RefinanceAmount?: number;
  TopupAmount?: number;
  CashAmount?: number;

  /** @deprecated use `durationTotalMonths` */
  Duration: number;
  MonthlyPayment: number;
  EffectiveRate: number;
  NominalRate: number;
  StartupFee?: number;
  AdministrationFee?: number;
  RequiresDocumentation: boolean;
  LoanType?: LoanType;

  // derived
  durationTotalMonths: number;
  informationType?: LoanInformationType;
  isHighCost: boolean;

  bankInfo?: string[];
};

export type CreditCardOfferData = OfferData & {
  creditCard: CreditCardCode;

  // direct
  Amount: number; // credit limit
  InterestRate: number; // EffectiveRate
  InterestFreeDays: number; // CreditCardInterestFreeDays

  // derived
};

/** type guards */

export const isLoanOffer = (
  offer: Offer
): offer is { type: 'loan'; data: LoanOfferData } => offer.type === 'loan';

export const isCreditCardOffer = (
  offer: Offer
): offer is { type: 'credit-card'; data: CreditCardOfferData } =>
  offer.type === 'credit-card';

//

export const OfferStates = [
  'neutral',
  'recommended',
  'canceled',
  'withdrawn',
  'expired',
  'selected',
  'paid',
] as const;
export type OfferState = (typeof OfferStates)[number];

//

export const LoanInformationTypes = [
  // loan_quote.LoanType
  'Consumer Loan',
  'Refinance Loan',
  'Combo Loan',
  'Topup Loan',
  // extended
  'Refinance Loan Dual Offer',
  'Combo Loan Detailed',
] as const;
export type LoanInformationType = (typeof LoanInformationTypes)[number];
